<template>
  <div>
    <v-dialog
      v-model="$store.state.dialog"
      width="50%"
      :fullscreen="isfullscreen"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card flat class="px-4 pt-4">
        <!-- dialog toolbar -->
        <v-toolbar height="50" flat>
          <h5>
            {{
              $store.state.itemDetails.dialogType == "add" ? "إضافة" : "تعديل"
            }}
            {{ this.$route.meta.single }}
          </h5>
          <v-spacer />
          <!-- <v-btn small icon fab color="" @click="isfullscreen = !isfullscreen">
                        <v-icon small>{{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}</v-icon>
                    </v-btn> -->
          <v-btn
            @click="close()"
            fab
            small
            icon
            color="grey"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mx-4" />

        <!-- form body -->
        <v-form
          @submit.prevent="submit()"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <v-container>
            <v-card flat class="py-4 px-1">
              <v-row>
                <v-col cols="12">
                  <h6 class="mb-1">
                    الصورة
                    <span class="required">*</span>
                  </h6>
                  <v-file-input
                    v-model="image"
                    ref="imageProject"
                    @change="InputImage(image)"
                    :rules="[validImage]"
                    :error-messages="errorMessage"
                    required
                    accept="image/*"
                    outlined
                    hide-details="auto"
                    filled
                    dense
                    placeholder="الصورة"
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-card flat outlined min-height="200">
                    <v-img max-height="400" contain :src="link"> </v-img>
                  </v-card>
                </v-col>

                <!-- attachments -->
              </v-row>
              <v-divider class="ml- mt-8" />

              <!-- actions -->
              <div class="mt-4">
                <v-btn
                  type="submit"
                  :loading="loading"
                  color="primary"
                  class="btn"
                  :disabled="!valid"
                >
                  حفظ
                  <v-icon small right>fi fi-rr-disk</v-icon>
                </v-btn>
                <v-btn
                  @click="close()"
                  text
                  class="mr-2 btn"
                  :disabled="loading"
                >
                  إلغاء
                </v-btn>
              </div>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    isfullscreen: false,
    valid: true,
    validImage: true,
    errorMessage: "",
    image: null,
    link: null,
    loading: false,
  }),

  created() {
    this.reset();
  },

  mounted() {
    this.$eventBus.$on("fill-fields", () => {
      this.body = this.$store.state.itemDetails;
    });
  },

  methods: {
    InputImage(image) {
      console.log(image);
      if (image && image.type && image.type.startsWith("image/")) {
        // If the image parameter exists and has a type starting with 'image/'
        // Convert the image to base64 and log the link
        this.ConvertImageToBase64(image, (base64Link) => {
          console.log(base64Link);
          this.link = base64Link;
        });
      } else {
        // If the image parameter is not of type 'image', make it empty or null
        console.log(image ? null : "");
        this.link = null;
        this.image = null;
        this.errorMessage = "File Must be type Of Image";
        this.valid = false;
        if (image) {
          this.$refs.imageProject.reset();
        }
      }
    },
    ConvertImageToBase64(image, callback) {
      const reader = new FileReader();

      reader.onloadend = function () {
        // Callback with the base64 link when the conversion is complete
        callback(reader.result);
      };

      reader.readAsDataURL(image);
    },
    reset() {
      this.image = null;
      this.link = null;
      this.errorMessage = null;
      this.validImage = true;
    },

    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.$store.state.itemDetails.dialogType == "add") {
          this.addItem();
        } else {
          this.editItem();
        }
      }
    },

    async addItem() {
      this.loading = true;
      let formData = new FormData();
      formData.append("image", this.image);
      await this.$http
        .post(`${this.$route.meta.endPoint}`, formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => {
          this.$emit(`refresh`);
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toastification/setToast", {
            message: `${err.response.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    async editItem() {
      this.loading = true;
      await this.$http
        .put(`${this.$route.meta.endPoint}/${this.body.id}`, this.body)
        .then((res) => {
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
          this.$eventBus.$emit(this.$route.path);
        })
        .catch((err) => {
          this.$store.dispatch("toastification/setToast", {
            message: `${err.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
